
.fade-in-slide {
  animation: fade-in-slide 1000ms linear both;
}

@keyframes fade-in-slide {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in {
  animation: fade-in 1000ms linear both;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.blur-animation {
  filter: blur(0);
  animation-name: blur;
  animation-duration: 3s;
}

.fast-blur-animation {
  filter: blur(0);
  animation-name: blur;
  animation-duration: 1s;
}

@keyframes blur {
  0% {
    opacity: 0;
    filter: blur(10px);
  }
  100% {
    opacity: 1;
    filter: blur(0);
  }
}

.slide-from-left {
  animation-name: slideFromLeft;
  animation-duration: 2s;
}

@keyframes slideFromLeft {
  0% {
    transform: translateX(-400px);
  }
  100% {
    transform: translateX(0);
  }
}

.initial-hidden {
  opacity: 0;
}